import { default as complete9aq40JbAS0Meta } from "/Users/monica/Sites/cb-cdp-frontend/pages/complete.vue?macro=true";
import { default as indexhv6UTr5InsMeta } from "/Users/monica/Sites/cb-cdp-frontend/pages/index.vue?macro=true";
import { default as maintenanceonhg2r6cQGMeta } from "/Users/monica/Sites/cb-cdp-frontend/pages/maintenance.vue?macro=true";
import { default as newsletter6kXvudkFbkMeta } from "/Users/monica/Sites/cb-cdp-frontend/pages/newsletter.vue?macro=true";
import { default as productj9rs1JzbO5Meta } from "/Users/monica/Sites/cb-cdp-frontend/pages/product.vue?macro=true";
import { default as registerN8RZbvAXn1Meta } from "/Users/monica/Sites/cb-cdp-frontend/pages/register.vue?macro=true";
import { default as serial_45number3idW93VBOAMeta } from "/Users/monica/Sites/cb-cdp-frontend/pages/serial-number.vue?macro=true";
export default [
  {
    name: "complete",
    path: "/complete",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/complete.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "maintenance",
    path: "/maintenance",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/maintenance.vue").then(m => m.default || m)
  },
  {
    name: "newsletter",
    path: "/newsletter",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/newsletter.vue").then(m => m.default || m)
  },
  {
    name: "product",
    path: "/product",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/product.vue").then(m => m.default || m)
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/register.vue").then(m => m.default || m)
  },
  {
    name: "serial-number",
    path: "/serial-number",
    component: () => import("/Users/monica/Sites/cb-cdp-frontend/pages/serial-number.vue").then(m => m.default || m)
  }
]